import React from "react";
import { graphql, PageProps } from "gatsby";

import Hero from "@shared/hero";
import SEO from "@shared/seo";
import { Query, SanityPowerConnection } from "@graphql-types";
import {
  isSanityCarousel,
  isSanityFullWidthImageBlock,
  isSanityLeftRightBlock,
  isSanityFeatureBlockArray,
} from "@util/types";
import {
  DefaultCarousel,
  FullWithImageBlock,
  LatestNewsBlock,
  LeftRightBlock,
  TabBlock,
} from "@global";
import { useRegionChanged } from "@util/hooks";

interface Data extends Query {
  altRegions: SanityPowerConnection;
}
interface Props extends PageProps {
  data: Data;
  pageContext: { iban: string };
}

export default function PowerPage({ data, pageContext }: Props) {
  const { nodes } = data.allSanityPower;
  const { powerHero, seo, components, latestNewsBlock } = nodes[0];
  const alternateSlugs = data.altRegions.nodes.map((alt) => alt.region);
  useRegionChanged("power", pageContext.iban);

  return (
    <div>
      <SEO seoData={seo} slug="power" alternateRegions={alternateSlugs} heroData={powerHero} />
      <Hero heroData={powerHero} />
      {components &&
        components.map((data) => {
          if (isSanityCarousel(data)) {
            return <DefaultCarousel data={data} key={data?._key} />;
          }
          if (isSanityLeftRightBlock(data)) {
            return (
              <div id={data.anchoredId ?? ""} key={data._key}>
                <LeftRightBlock data={data} />
              </div>
            );
          }
          if (isSanityFullWidthImageBlock(data)) {
            return (
              <div id={data.anchoredId ?? ""} key={data._key}>
                <FullWithImageBlock data={data} />
              </div>
            );
          }
          if (isSanityFeatureBlockArray(data)) {
            return <TabBlock data={data} key={data?._key} baseSlug="power" />;
          }
          return null;
        })}
      {latestNewsBlock?.displayToggle && <LatestNewsBlock data={latestNewsBlock} />}
    </div>
  );
}

export const query = graphql`
  query PowerPageQuery($iban: String) {
    allSanityPower(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        powerHero {
          ...sanityHero
        }
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        components {
          ... on SanityCarousel {
            ...sanityCarousel
          }
          ... on SanityFeatureBlockArray {
            ...sanityFeatureBlockArray
          }
          ... on SanityFullWidthImageBlock {
            ...sanityFullWidthImageBlock
          }
          ... on SanityLeftRightBlock {
            ...sanityLeftRightBlock
          }
        }
        latestNewsBlock {
          ...sanityLatestNewsBlock
        }
      }
    }
    altRegions: allSanityHome {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
